


import {
  PropType,
  defineComponent,
  useRoute,
  useMeta,
  watch,
  ref,
  Ref,
  useContext,
} from '@nuxtjs/composition-api';
import { SeoMeta } from './types';
import { getSeoDescription, getSeoTitle } from './utils';

export const noIndexRoutes = [
  '/kategorien/fahrraeder',
  '/kategorien/fahrrad',
  '/kategorien/e-bikes',
  '/kategorien/e-bike',
  '/kategorien/light-e-bikes',
  '/kategorien/light-e-bike',
];

const siteName = 'Bernhard Kohl - Fahrrad & Fitness';

// check for routes and sub routes
// e.g. /kategorien/fahrrad/rahmensets should return true
const isInNoIndexRoutes = (route: string) => noIndexRoutes.some((noIndexRoute) => route.includes(noIndexRoute));

export default defineComponent({
  name: 'AppSeoMeta',
  props: {
    seoMeta: {
      type: Object as PropType<SeoMeta>,
      required: true,
    },
  },
  // we cannot use head() here as we need to have access to the route
  // thus we use useMeta() inside setup()
  setup(props) {
    const { app: { i18n } } = useContext();
    const seo: Ref<SeoMeta | null> = ref(null);

    const route = useRoute();
    const isNoIndexRoute = isInNoIndexRoutes(route.value.path);

    // since we have dynamic route changes, we need to run useMeta multiple times in some cases
    // every time the seo prop changes
    watch(
      () => props.seoMeta,
      (newValue) => {
        seo.value = newValue;
      },
      { immediate: true },
    );

    useMeta(() => {
      if (!seo.value) {
        return {
          title: siteName,
        };
      }

      const {
        title,
        description,
        og_title,
        og_description,
        og_image,
        twitter_title,
        twitter_description,
        twitter_image,
        keywords,
        manufacturer,
        is_manufacturer_category,
      } = seo.value;

      let pageTitle = (!manufacturer || title?.startsWith(manufacturer))
        ? title
        : `${manufacturer} ${title}`;
      if (is_manufacturer_category) {
        pageTitle = i18n.t('Manufacturer SEO text', { manufacturerPrefix: pageTitle }).toString();
      }

      return {
        title: getSeoTitle(pageTitle),
        meta: [
          { property: 'description', content: getSeoDescription(description) },
          { property: 'og:title', content: getSeoTitle(og_title) },
          { property: 'og:description', content: getSeoDescription(og_description) },
          { property: 'og:image', content: og_image },
          { property: 'twitter:title', content: getSeoTitle(twitter_title) },
          { property: 'twitter:description', content: getSeoDescription(twitter_description) },
          { property: 'twitter:image', content: twitter_image },
          { property: 'keywords', content: keywords },
          { property: 'apple-mobile-web-app-title', content: siteName },
          { property: 'og:site_name', content: siteName },
          isNoIndexRoute && { property: 'robots', content: 'noindex' },
        ]
          .filter((tag) => tag.content)
          .map(({ property, content }) => ({
            hid: property,
            name: property,
            content,
          })),
      };
    });
  },
  // empty head necessary to active useMeta() composable
  head: {},
});
